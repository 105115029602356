<template>
    <div class="empty">
        검색결과가 없습니다.
    </div>
</template>
<script>
    import EventBus from "@/utils/event-bus";

    export default {
        name: "MemberEmptyDefaultLayout",
        props: {
        },
        data() {
            return {
                noDate: false,
            }
        },
        computed: {},
        mounted() {

        },
        methods: {
        },
    }
</script>

<style scoped>

</style>